:root {
  --beak-green: #00D55E;
  --evergreen: #4CAE76;
  --heart-red: #DB7970;
  --book-blue: #6689C2;
  --background-beige: #FAEDCC;
  --shadow-beige: #B4A277;
  --border-brown: #75430D;
}

@font-face {
  font-family: "Futura 1";
  src: local("Futura Heavy"), url(./fonts/Futura\ Heavy.ttf), format("ttf");
}

@font-face {
  font-family: "Futura 2";
  src: local("Futura Medium bt"), url(./fonts/Futura\ Medium\ bt.ttf), format("ttf");
}

.App {
  text-align: center;
}

.navbar-link {
  font-family: "Futura 1";
  padding: 5px;
  color: var(--beak-green);
}

.nav-link {
  padding: 5px;
  color: var(--beak-green);
  text-decoration: none;
}

h1 {
  font-family: "Futura 2";
  font-size: 60px;
  color: var(--evergreen);
}

h2 {
  font-family: "Futura 2";
  font-size: 45px;
  color: var(--book-blue);
}

h3 {
  font-family: "Futura 2";
  font-size: 24px;
  color: var(--heart-red);
}

p {
  font-family: "Futura 1";
}

.that-logo {
  width: 150px;
  height: 150px;
  margin-top: 35px;
}

.navbar {
  display: inline-block;
}

.socialbar {
  margin-top: 25px;
  margin-bottom: -25px;
  display: block;
  border-style: line;
  border: 2px;
}

.socialbutton {
  font-family: "Futura";
  color: var(--border-brown);
  background-color: var(--background-beige);
  height: 40px;
  border-style: none;
  margin: 10px, 20px, 10px, 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
}

.social-logo {
  width: 300px;
  height: 300px;
}

.navbar-link {
  font-family: "Futura";
  font-size: 28px;
  margin-left: 25px;
  margin-right: 25px;
  text-decoration: none;
}

.center-column {
  align-self: center;
  width: 50vw;
  margin: auto;
}

.project-box {
  display: inline-flex;
  width: 100%;
  margin: 50px auto 50px auto;
}